<template>
  <div class="animated fadeIn container">
    <h1>充值内容</h1>
    <table class="border-table mt-4" v-if="items.length>0">
      <thead>
        <tr>
          <td width="20%">要求充值时间</td>
          <td width="50%">充值者名字</td>
          <td width="20%">条数</td>
          <td width="20%">状态</td>
        </tr>
      </thead>
      <tbody>
        <tr :key=item.id v-for="item in items">
          <td>
            <span class="mr-3">{{ item.createdAt | dateFormat }}</span>
          </td>
          <td>
            {{ item.depositor }}
          </td>
          <td>
            {{ item.money | numberFormat }} 条
          </td>
          <td>
            {{ statusText(item.status) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import DepositService from '@/services/DepositService'

  export default {
    name: 'DepositHistory',
    data() {
      return {
        items: []
      }
    },
    mounted() {
      document.getElementsByClassName('app-body')[0].classList.add('bg-white')

      this.getRequests()
    },
    methods: {
      async getRequests() {
        const response = await DepositService.getRequests()
        if (response.status == 200) {
          this.items = response.data.list
        }
      },
      statusText(status) {
        if (status == 0) {
          return '等待确认'
        } else if (status == 1) {
          return '拒绝'
        } else if (status == 2) {
          return '同意'
        } else {
          return 'error'
        }
      }
    }
  }

</script>
